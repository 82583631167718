import { createGlobalStyle } from "styled-components"


const GlobalStyles = createGlobalStyle`




p {
    font-family: "Aquawax Pro";
    font-weight: 400;
    font-display: block;
}
h1 {
    font-family: "Aquawax Pro", sans-serif;
    font-size: 2.5em;
    margin-top: 0;
    font-weight: 900;
    font-display: block;
}
h2 {
    font-family: "Aquawax Pro", sans-serif;
    font-weight: bold;
    font-size: 2.0em;
    font-weight: 900;
    font-display: block;
}
h3 {
    font-family: "Aquawax Pro", sans-serif;
    font-size: 1.7em;
    font-weight: 800;
    font-style: normal;
}
h4 {
    font-family: "Aquawax Pro", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
	  font-size: 0.7em;
	  letter-spacing: 2.1px;
}
h5 {
    font-family: "Aquawax Pro", sans-serif;
    font-size: 1.0em;
    font-weight: 300;
}
h6 {
    font-family: "Aquawax Pro", sans-serif;
}
blockquote {
    font-style: italic;
}

.h1, .h2, .h3, .h3, .h4, .h6, h1, h2, h3, h4, h5, h6, p, blockquote {
    margin: 10px 0;
    padding: 0;
}
@media (max-width: 768px) {
    html {
        font-size: 19px;
   }
   h2 {
       font-size: 1.5em;
   }
   h3 {
       font-size: 1.3em;
   }
}

`

export default GlobalStyles
