import * as React from "react"
import { Link } from "gatsby"
import { Center } from '@mantine/core';
import { Row, Col, Container, Button } from "react-bootstrap" 
import { Group } from '@mantine/core';
import { Grid } from '@mantine/core';
import Logo from "./logo"

const logo = "../images/azul-logo.png"



const Navbar = () => {
	return (
	<nav className="navbar">
		<header>
			<div className="contianer-fluid fixed-top">
				<div className="header">
				<Container>
	    			<div className="flex">
						<div className="block" id="left">
							<div className="header-left" id="flex">
								<Link to="/"> <Logo /> </Link>
							</div>
						</div>

						<div className="block" id="center">
							<Center>
							<div className="header-center">
								<Center>
									<div className="directives" id="flex">
										<h4><Link activeClassName="active" to="/">Home</Link><br /></h4>
										<h4><a href="#who-we-work-with">Who We Work With</a><br /></h4>
										<h4><a href="#real-estate">Real Estate & Residency</a><br /></h4>
										<h4><a href="#about-us">About</a><br /></h4>
									</div>
								</Center>
							</div>
							</Center>
						</div>

						<div className="block" id="right">
							<div className="header-right">
								<div className="contact" id="flex">
								<Group spacing="xs">


									<div className="block"> 
									<a href="#contact-us">
										<div className="social-wrapper">
											<div className="social-icon">
											<Center>
												
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g opacity="0.9">
												<path d="M22.7999 17.3998V6.5998C22.7999 5.6038 21.9959 4.7998 20.9999 4.7998H4.18794C3.19194 4.7998 2.38794 5.6038 2.38794 6.5998V17.3998C2.38794 18.3958 3.19194 19.1998 4.18794 19.1998H20.9999C21.9959 19.1998 22.7999 18.3958 22.7999 17.3998ZM21.2279 6.4678C21.6239 6.8638 21.4079 7.2718 21.1919 7.4758L16.3199 11.9398L20.9999 16.8118C21.1439 16.9798 21.2399 17.2438 21.0719 17.4238C20.9159 17.6158 20.5559 17.6038 20.3999 17.4838L15.1559 13.0078L12.5879 15.3478L10.0319 13.0078L4.78794 17.4838C4.63194 17.6038 4.27194 17.6158 4.11594 17.4238C3.94794 17.2438 4.04394 16.9798 4.18794 16.8118L8.86794 11.9398L3.99594 7.4758C3.77994 7.2718 3.56394 6.8638 3.95994 6.4678C4.35594 6.0718 4.76394 6.2638 5.09994 6.5518L12.5879 12.5998L20.0879 6.5518C20.4239 6.2638 20.8319 6.0718 21.2279 6.4678V6.4678Z" fill="#AAEAFF"/>
												</g>
												</svg>

											</Center>
											</div>
										</div>
									</a>
									</div>


									<div className="block"> 
										<div className="social-wrapper">
										<a href="tel:12133428475" data-rel="external">
											<div className="social-icon">
											<Center>
												
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g opacity="0.9">
												<path d="M20 10.999H22C22 5.869 18.127 2 12.99 2V4C17.052 4 20 6.943 20 10.999Z" fill="#AAEAFF"/>
												<path d="M13 8.00024C15.103 8.00024 16 8.89724 16 11.0002H18C18 7.77524 16.225 6.00024 13 6.00024V8.00024ZM16.422 13.4432C16.2299 13.2686 15.9774 13.1754 15.7178 13.1835C15.4583 13.1915 15.212 13.3001 15.031 13.4862L12.638 15.9472C12.062 15.8372 10.904 15.4762 9.71204 14.2872C8.52004 13.0942 8.15904 11.9332 8.05204 11.3612L10.511 8.96724C10.6975 8.78637 10.8062 8.54006 10.8142 8.28045C10.8222 8.02083 10.7289 7.76828 10.554 7.57624L6.85904 3.51324C6.68408 3.3206 6.44092 3.20374 6.18119 3.1875C5.92146 3.17125 5.66564 3.2569 5.46804 3.42624L3.29804 5.28724C3.12515 5.46075 3.02196 5.69169 3.00804 5.93624C2.99304 6.18624 2.70704 12.1082 7.29904 16.7022C11.305 20.7072 16.323 21.0002 17.705 21.0002C17.907 21.0002 18.031 20.9942 18.064 20.9922C18.3086 20.9786 18.5394 20.8749 18.712 20.7012L20.572 18.5302C20.7415 18.3328 20.8273 18.077 20.8113 17.8173C20.7952 17.5576 20.6785 17.3143 20.486 17.1392L16.422 13.4432V13.4432Z" fill="#AAEAFF"/>
												</g>
												</svg>

											</Center>
											</div>

											</a>
										</div>
									</div>


									<a href="#contact-us">
									<Button id="button-one"><h4>Contact Us</h4></Button>
									</a>


								</Group>
								</div>
							</div>
						</div>
					</div>
				</Container>
				</div>
			</div>
		</header>

	</nav>
	);
}

export default Navbar;