import * as React from "react"


const Footer = () => {
	return (
        <footer>
            <div className="footer-wrapper">
                
            </div>
        </footer>
    );
}
export default Footer;