/*
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import 'bootstrap/dist/css/bootstrap.css';
import "./../css/assets/styles.scss";
import "./../css/assets/head-foot.scss";
import Navbar from "./navbar";
import Footer from "./footer";
import GlobalStyles from "./GlobalStyles"
import "./../fonts/fonts.css"


const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    return (
        <>
            <div className="App">
                <Navbar />
                <div className="content">
                    <div>
                        <GlobalStyles />
                        <main>{children}</main>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
